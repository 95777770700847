

























































import { CommonModule } from '@/store/modules/common'
import moment from 'moment'
import { Component, Vue } from 'vue-property-decorator'
import { getRechargeList } from '@/pages/user/api'
import { getCoinDetailList } from '@/pages/videoGame/api'
import { LoyoutModule } from '@/store/modules/layout'
@Component({
  name: 'WalletDetail'
})
export default class extends Vue {
  list: any = []
  loading = false
  finished = false
  refreshing = false
  pageInfo = {
    pageSize: 20,
    currPage: 0,
    total: 0
  }
  typeMap = {
    1: '签到奖励',
    2: '邀请注册奖励',
    3: '邀请充值奖励',
    4: '周六会员日-会员礼包',
    5: '周六会员日-消费返币',
    6: '平台赠送',
    7: '积分兑换',
    8: '游戏消耗'
  }
  get token() {
    return CommonModule.token
  }
  async getList(type?: string) {
    const params = {
      page: type == 'refresh' ? 1 : this.pageInfo.currPage + 1,
      limit: this.pageInfo.pageSize
    }
    const { data } = await getCoinDetailList(params)
    data.list.forEach((item: any) => {
      if (
        item.inOutType != 8 &&
        item.inOutType != 12 &&
        item.inOutType != 13 &&
        item.inOutType != 5 &&
        item.inOutType != 6
      ) {
        item.isCommon = true
      }
      if (
        item.inOutType == 12 ||
        item.inOutType == 13 ||
        item.inOutType == 5 ||
        item.inOutType == 6
      ) {
        item.subTitle = '活动奖励'
      }
      item.showName = item.inOutTypeName
    })
    if (this.refreshing) {
      this.list = []
      this.refreshing = false
    }
    this.loading = false
    this.list.push(...data.list)
    this.pageInfo = data.pageInfo
    if (CommonModule.userId == 44732) {
      //该用户要求隐藏充值记录
      this.list = this.list.filter((item: any) => {
        return item.inOutType != 8
      })
    }

    if (data.list.length < this.pageInfo.pageSize) {
      this.finished = true
    }
  }
  onRefresh() {
    // 清空列表数据
    this.finished = false
    console.log('onRefresh')
    // 重新加载数据
    // 将 loading 设置为 true，表示处于加载状态
    this.loading = true
    this.getList('refresh')
  }
  created() {
    //
    CommonModule.getToken()
    LoyoutModule.setTitle(this.$t('明细'))
  }
  getDate(t: number) {
    return moment(t * 1000).format('YYYY-MM-DD HH:mm:ss')
  }
}
